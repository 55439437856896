import { environment } from './environment';

export const settings = {
  ...environment,
  appVersion: '1.0.0',
  // appName: '',
  // appleId: '',
  // playStoreUrl: 'market://details?id=',
  // storageKeyUserToken: 'user_token',
  // storageKeyUserDetails: 'user_details',
  // storageKeyRating: 'rating',
};
