import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { from, Observable, Subject } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { settings } from 'src/environments/settings';
import { Product } from '../models/product';
import { RecipeService } from './recipe.service';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  all: Product[];

  subject: Subject<any> = new Subject<any>();
  stream: Observable<any> = this.subject.asObservable();

  recipeService: RecipeService;

  private productsStorageKey = 'products';

  constructor(
    private http: HttpClient,
    private storage: Storage,
  ) { }

  onReady(callback: (products: Product[]) => void): void {
    if (this.all) {
      callback(this.all);
    }

    this.stream.subscribe(() => {
      return callback(this.all);
    });
  }

  boot() {
    this.restore().then(
      () => this.recipeService.restore()
    ).then(
      () => this.load().subscribe(
        () => this.recipeService.load().subscribe()
      )
    );
  }

  restore() {
    return this.storage.get(this.productsStorageKey).then(
      (products) => {
        if (products) {
          this.all = products.map(x => {
            const product = new Product(x);

            product.fresh = false;
            product.media_url = x.media_url;

            return product;
          });
        }
      }
    );
  }

  store() {
    return this.storage.set(this.productsStorageKey, this.all);
  }

  find(id: number) {
    if (! this.all) {
      return null;
    }

    return this.all.find(x => x.id === id);
  }

  load(): Observable<Product[]> {
    return this.loadPage().pipe(
      map((res: any[] ) => {
        const products = res.map(x => {
          return new Product(x);
        });

        if (this.all) {
          products.forEach(x => {
            const product = this.find(x.id);

            x.fresh = true;

            product ? product.update(x) : this.all.push(x);
          });

          this.all = this.all.filter(x => x.fresh);
        }
        else {
          this.all = products;
        }

        this.recipeService.onReady(() => {
          this.all.forEach(x => this.loadMedia(x).subscribe());
        });

        this.store();

        this.subject.next(this.all);

        return this.all;
      }),
    );
  }

  loadPage(page = 1, products: any[] = []): Observable<Product[]> {
    return this.http.get(`${settings.apiEndpoint}/wp/v2/products?per_page=100&page=${page}`).pipe(
      mergeMap((res: any[] ) => {
        return this.loadPage(++page, products.concat(res));
      }),
      catchError(() => {
        return [products];
      })
    );
  }

  get(product: Product): Observable<Product> {
    if (product.acf) {
      return from([product]);
    }

    return this.http.get(`${settings.apiEndpoint}/acf/v3/products/${product.id}`).pipe(
      map((res: { acf: any }) => {
        product.setAcf(res.acf);

        return product;
      }),
    );
  }

  loadMedia(product: Product): Observable<Product> {
    if (!product.featured_media || product.media_url) {
      return from([product]);
    }

    return this.http.get(`${settings.apiEndpoint}/wp/v2/media/${product.featured_media}`).pipe(
      map((res: { source_url: string }) => {
        product.media_url = res.source_url;

        this.store();

        return product;
      }),
    );
  }

  fetch(id: number): Observable<Product> {
    const product = this.find(id);

    if (product) {
      return this.get(product);
    }

    return this.load().pipe(mergeMap(() => this.get(this.find(id))));
  }
}
