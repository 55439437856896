import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { from, Observable, Subject } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { settings } from '../../environments/settings';

import { Recipe } from '../models/recipe';
import { ProductService } from './product.service';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class RecipeService {

  all: Recipe[];

  subject: Subject<any> = new Subject<any>();
  stream: Observable<any> = this.subject.asObservable();

  private recipesStorageKey = 'recipes';

  constructor(
    private http: HttpClient,
    private storage: Storage,
    private productService: ProductService,
  ) {
    this.productService.recipeService = this;
  }

  onReady(callback: (recipes: Recipe[]) => void): void {
    if (this.all) {
      callback(this.all);
    }

    this.stream.subscribe(() => {
      return callback(this.all);
    });
  }

  restore() {
    return this.storage.get(this.recipesStorageKey).then(
      (recipes) => {
        if (recipes) {
          this.all = recipes.map(x => {
            const recipe = new Recipe(x);

            recipe.fresh = false;
            recipe.media_url = x.media_url;

            return recipe;
          });
        }
      }
    );
  }

  store() {
    return this.storage.set(this.recipesStorageKey, this.all);
  }

  find(id: number) {
    if (! this.all) {
      return null;
    }

    return this.all.find(x => x.id === id);
  }

  load(): Observable<Recipe[]> {
    return this.loadPage().pipe(
      map((res: Recipe[] ) => {
        const recipes = res.map(x => {
          return new Recipe(x);
        });

        if (this.all) {
          recipes.forEach(x => {
            const recipe = this.find(x.id);

            x.fresh = true;

            recipe ? recipe.update(x) : this.all.push(x);
          });

          this.all = this.all.filter(x => x.fresh);
        }
        else {
          this.all = recipes;
        }

        this.all.forEach(x => this.loadMedia(x).subscribe());

        this.store();

        this.subject.next(this.all);

        return this.all;
      }),
    );
  }

  loadPage(page = 1, recipes: any[] = []): Observable<Recipe[]> {
    return this.http.get(`${settings.apiEndpoint}/wp/v2/recipes?per_page=100&page=${page}`).pipe(
      mergeMap((res: any[] ) => {
        return this.loadPage(++page, recipes.concat(res));
      }),
      catchError(() => {
        return [recipes];
      })
    );
  }

  get(recipe: Recipe): Observable<Recipe> {
    if (recipe.acf) {
      return from([recipe]);
    }

    return this.http.get(`${settings.apiEndpoint}/acf/v3/recipes/${recipe.id}`).pipe(
      map((res: { acf: any }) => {
        recipe.setAcf(res.acf);

        recipe.acf.product_selection = res.acf.product_selection.map(x => this.productService.find(x.ID));

        return recipe;
      }),
    );
  }

  loadMedia(recipe: Recipe): Observable<Recipe> {
    if (recipe.media_url) {
      return from([recipe]);
    }

    return this.http.get(`${settings.apiEndpoint}/wp/v2/media/${recipe.featured_media}`).pipe(
      map((res: { source_url: string }) => {
        recipe.media_url = res.source_url;

        this.store();

        return recipe;
      }),
    );
  }

  fetch(id: number): Observable<Recipe> {
    const recipe = this.find(id);

    if (recipe) {
      return this.get(recipe);
    }

    return this.load().pipe(mergeMap(() => this.get(this.find(id))));
  }
}
