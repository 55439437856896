export class Product {
  fresh = false;
  id: number = null;
  date: string | Date = null;
  date_gmt: string | Date = null;
  guid: {
    rendered: string;
  } = null;
  modified: string | Date = null;
  modified_gmt: string | Date = null;
  slug: string = null;
  status: 'publish' = null;
  type: 'products' = null;
  link: string = null;
  title: {
    rendered: string;
  } = null;
  content: {
    rendered: string;
    protected: boolean;
  } = null;
  featured_media: number = null;
  media_url: string;
  template: string = null;
  productcategories: number[] = null;
  _links: any = null;
  acf: {
    'product-description': string;
    'bar-code': string;
    weight: string;
    διαθεσιμότητα: string;
    ingredients: string;
    allergens: string;
    'energya-nutrition': string;
    'energyb-nutrition_copy': string;
    'lipara-nutrition': string;
    'koresmena-nutrition': string;
    'ydatanthrakes-nutrition': string;
    'sakxara-nutrition': string;
    'edines-nutrition': string;
    'protein-nutrition': string;
    'salt-nutrition': string;
    product_photos: {
      ID: number;
      id: number;
      title: string;
      filename: string;
      filesize: number;
      url: string;
      link: string;
      alt: string;
      author: string;
      description: string;
      caption: string;
      name: string;
      status: 'inherit',
      uploaded_to: number;
      date: string | Date;
      modified: string | Date;
      menu_order: number;
      mime_type: string;
      type: 'image',
      subtype: 'jpeg',
      icon: string;
      width: number;
      height: number;
      sizes: any;
    }[];
  };

  constructor(data) {
    for (const key of Object.keys(data)) {
      if (this.hasOwnProperty(key)) {
        this[key] = data[key];
      }
    }
  }

  update(data) {
    for (const key of Object.keys(data)) {
      if (this.hasOwnProperty(key)) {
        this[key] = data[key];
      }
    }
  }

  setAcf(data) {
    this.acf = data;
  }
}
