import { Product } from './product';

export class Recipe {
  fresh = false;
  id: number = null;
  date: string | Date = null;
  date_gmt: string | Date = null;
  guid: {
    rendered: string;
  } = null;
  modified: string | Date = null;
  modified_gmt: string | Date = null;
  slug: string = null;
  status: string = null;
  type: string = null;
  link: string = null;
  title: {
    rendered: string;
  } = null;
  featured_media: number = null;
  media_url: string;
  template: string = null;
  recipes: number[] = [];
  _links: any = null;
  acf: {
    Ingredient_title: string;
    icon_boxes: {
      icon: string;
      'time-number': string;
      title: string;
    }[];
    ingredient_2_items: {
      item_text: string;
    }[];
    ingredient_area_1_title: string;
    ingredient_area_2_title: string;
    ingredient_items: {
      item_text: string;
    }[];
    method_first_area_title: string;
    method_items: {
      method_content: string;
    }[];
    method_second_area_title: string;
    method_second_items: {
      method_second_content: string;
    }[];
    method_title: string;
    product_selection: Product[]
    relationship_name: string;
  };

  constructor(data) {
    for (const key of Object.keys(data)) {
      if (this.hasOwnProperty(key)) {
        this[key] = data[key];
      }
    }
  }

  update(data) {
    for (const key of Object.keys(data)) {
      if (this.hasOwnProperty(key)) {
        this[key] = data[key];
      }
    }
  }

  setAcf(data) {
    this.acf = data;
  }
}
